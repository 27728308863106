<template>
    <div class="app flex-row align-items-center">
        <div class="container">
            <b-row class="justify-content-center ">
                <b-col md="6">
                    <h5>Create New Password</h5>
                    <b-form-group description="new password">
                        <b-input-group>
                            <b-input-group-text slot="append"><fa-icon :icon="['fas', 'eye']" @mousedown="passwordPeek =  true" @mouseup="passwordPeek = false"/></b-input-group-text>
                            <b-form-input v-model="password" placeholder="Password"
                                          class="form-control" :class="{ 'border-danger': (!password || password.length < 8)}"
                                          autofocus :type="passwordPeek ? 'text' : 'password'"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-form-group description="confirm new password">
                        <b-input-group>
                            <b-input-group-text slot="append"><fa-icon :icon="['fas', 'eye']" @mousedown="passwordPeek2 =  true" @mouseup="passwordPeek2 = false"/></b-input-group-text>
                            <b-form-input v-model="reenteredPassword" placeholder="Re-Enter Password"
                                          class="form-control" :class="{ 'border-danger': (!reenteredPassword || reenteredPassword.length < 8)}"
                                          autofocus :type="passwordPeek2 ? 'text' : 'password'"></b-form-input>
                        </b-input-group>
                    </b-form-group>
                    <b-button size="sm" variant="primary" @click="submitPassReset">Submit</b-button>
                </b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import iss from '@/services/iss';

export default {
    name: 'PasswordReset',
    data: () => {
        return {
            password: "",
            reenteredPassword: "",
            passwordPeek: false,
            passwordPeek2: false,
        }
    },
    methods: {
        submitPassReset() {
            if(this.password.length < 8) {
                this.$mToast({
                    title: 'Error',
                    text: "Password must be at least 8 characters long.",
                    style: 'error'
                });

            } else if (this.password !== this.reenteredPassword) {
                this.$mToast({
                    title: 'Error',
                    text: "Passwords do not match! Please re-enter.",
                    style: 'error'
                });

            } else {
                iss.passReset(this.$route.query.tokenId, this.password).then(response => {
                    if (response.status === 200) {
                        this.$mToast({
                            title: 'Success',
                            text: "Succesfully updated password! Redirecting to login screen in 3 seconds.",
                            style: 'success'
                        });
                        setTimeout(() => {
                            window.location = '/#/pages/login';
                            location.reload();
                        }, 3000);
                    } else {
                        this.$mToast({
                            title: 'Error',
                            text: "Error updating password, please contact support!",
                            style: 'error'
                        });
                    }
                }).catch(() => {
                    this.$mToast({
                        title: 'Error',
                        text: "Error updating password, please contact support!",
                        style: 'error'
                    });
                });
            }
        }
    }
}
</script>
